import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  ListItemSecondaryAction,
  Button,
} from '@mui/material';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { AudioConversionResponseForm } from '../constants/api';

interface Props extends AudioConversionResponseForm {}

const AudioResponseItem: React.FC<Props> = ({ name, url }) => {
  return (
    <ListItem
      divider
      disableGutters
      sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
    >
      <ListItemIcon>
        <AudioFileIcon sx={{ fontSize: '2.5rem' }} />
      </ListItemIcon>

      <ListItemText>
        <Typography variant="body1">{name}</Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        <Button
          variant="contained"
          color="primary"
          startIcon={<PlayArrowIcon />}
          href={url}
          target="_blank"  // Open in a new tab
          rel="noopener noreferrer"  // Security reasons
        >
          Play
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default AudioResponseItem;
