import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  List,
  TextField,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAuth } from '../context/AuthContext';
import { useModal } from '../context/ModalContext';
import GoogleSignInButton from '../components/GoogleSignInButton';
import SoundSegLogo from './SoundSegLogo';
import { useState } from 'react';
import AudioResponseListItem from './AudioResponseListItem';
import {
  AudioConversionRequestForm,
  AudioConversionResponseForm,
  WebResponse,
} from '../constants/api';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const LOGIN_MODAL_ID = 'login';

const urlPattern =
  /.+/;

const getHelperText = (error: any) => {
  if (!error) return '';
  switch (error.type) {
    case 'required':
      return 'Music video URL is required';
    case 'pattern':
      return 'Invalid music video URL';
    default:
      return error.message;
  }
};

const SubmitAudioForm = () => {
  const { user } = useAuth();
  const { queueModal, closeModal } = useModal();
  const { register, handleSubmit, formState, setError, setValue } =
    useForm<AudioConversionRequestForm>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseData, setResponseData] = useState<
    AudioConversionResponseForm[]
  >([]);
  const areFilesReady = !!responseData?.length;
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const functions = getFunctions(getApp());
  const segment = httpsCallable<
    AudioConversionRequestForm,
    WebResponse<AudioConversionResponseForm[]>
  >(functions, 'segement');
  const storage = getStorage();

  function fileToBase64(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        
        // Set up the onload event, which runs once the file is read
        reader.onload = () => resolve(reader.result?.toString().split(',')[1]); // Get base64 string, excluding metadata
        reader.onerror = (error) => reject(error); // Handle errors
        
        reader.readAsDataURL(file); // Read file as Data URL
    });
}

  const onAuthenticatedSubmit = async (data: AudioConversionRequestForm) => {
    setIsSubmitting(true);
    setResponseData([]); // Clear previous data
    
    const formData = {
      youtubeUrl: data.youtubeUrl || undefined,
      audioFile: uploadedFile ? await fileToBase64(uploadedFile) : undefined,
    } as AudioConversionRequestForm;
    // check if the audio file is larger than 10MB
    if (uploadedFile && uploadedFile.size > 10000000) {
      setError('youtubeUrl', { type: 'manual', message: 'Audio file is too large. Please upload a smaller file.' });
      return;
    }
    try {
      const res = await segment(formData);
      if (res.data.success) {
        var resData = res.data.data;
        // Ensure all URLs are fetched before setting the state
        for (let i = 0; i < resData?.length; i++) {
          const storageRef = ref(storage, resData[i].url);
          resData[i].url = await getDownloadURL(storageRef);
        }
        setResponseData(resData as AudioConversionResponseForm[]); // Set the response data
        // clear the uploaded file
        setUploadedFile(null);
      } else {
        setError('youtubeUrl', { type: 'manual', message: res.data.message });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }

    setIsSubmitting(false);
  };

  const onSubmit = (data: AudioConversionRequestForm) => {
    if (!user) {
      queueModal(
        <Grid container direction="column" alignItems="center" spacing={2}>
          <Grid item>
            <SoundSegLogo logoWidth="6rem" fontSize="2rem" />
          </Grid>
          <Grid item>
            <Typography variant="h5" align="center">
              Please sign in to use this feature
            </Typography>
          </Grid>

          <Grid item>
            <GoogleSignInButton
              onSuccess={() => {
                onAuthenticatedSubmit(data);
                closeModal(LOGIN_MODAL_ID);
              }}
            />
          </Grid>
        </Grid>,
        LOGIN_MODAL_ID
      );
    } else {
      // Check if either a URL or a file is provided
      if (!data.youtubeUrl && !uploadedFile) {
        setError('youtubeUrl', { type: 'manual', message: 'Please provide a music video URL or upload an audio file.' });
        return;
      }
      onAuthenticatedSubmit(data);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  const handleDemoClick = async () => {
    const demo_url = 'https://www.youtube.com/watch?v=TLV4_xaYynY&ab_channel=JimiHendrixVEVO';
    console.log("Demo button clicked");

    // Set the text field to the demo URL
    setValue('youtubeUrl', demo_url);

    // Fetch audio files from the demo_audio folder in the assets directory
    const demoFiles = ['jimi_drums.mp3', 'jimi_bass.mp3', 'jimi_other.mp3', 'jimi_vocals.mp3']; // Replace with actual file names
    const demoResponseData: AudioConversionResponseForm[] = [];

    for (const fileName of demoFiles) {
      const storageRef = ref(storage, `/assets/demo_audio/${fileName}`);
      const fileUrl = await getDownloadURL(storageRef);
      demoResponseData.push({ name: fileName, url: fileUrl });
    }

    // Set the response data with the demo files
    setResponseData(demoResponseData);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">
              Input a valid music video URL or upload an audio file to get started
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="https://www.youtube.com/watch?v=..."
                sx={{ marginTop: '1rem' }}
                error={!!formState.errors.youtubeUrl}
                helperText={getHelperText(formState.errors.youtubeUrl)}
                {...register('youtubeUrl', {
                  required: !uploadedFile, // Make URL required only if no file is uploaded
                  pattern: urlPattern,
                })}
              />

              <input
                type="file"
                accept="audio/*"
                onChange={handleFileChange}
                style={{ marginTop: '1rem' }}
              />

              <Box sx={{ display: 'flex', justifyContent: 'center', gap: '0.5rem', marginTop: '1rem', alignItems: 'center' }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  size="small"
                >
                  Submit
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleDemoClick}
                  size="small"
                >
                  Show Demo
                </Button>
              </Box>
            </form>
            <Typography variant="body2" color="textSecondary" gutterBottom sx={{ marginTop: '1rem' }}>
              Note: YouTube occasionally blocks our requests. It's safer to upload an audio file or test our demo. You can also use music videos from other platforms.
            </Typography>
          </CardContent>
        </Card>
      </Grid>

      {isSubmitting && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid
                container
                alignItems="center"
                direction="column"
                spacing={3}
              >
                <Grid item>
                  <Typography align="center">
                    This can take up to a minute to complete...
                  </Typography>
                </Grid>
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}

      {areFilesReady && (
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Your file{responseData?.length > 1 ? 's are' : ' is'} ready for
                download:
              </Typography>
              <List>
                {responseData?.map(
                  (item: AudioConversionResponseForm, index: number) => (
                    <AudioResponseListItem key={index} {...item} />
                  )
                )}
              </List>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default SubmitAudioForm;
